.workExperienceMainContainer{
    margin: 2rem 8rem;
    /* border: 5px solid rgb(101, 52, 194); */
    height: 100%;
    display: flex;
    flex-direction: column;
    background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.workExperienceHeader > h1{
    text-align: justify;
    font-size: 50px;
    margin-left: 2rem;
    margin-bottom: 0;
    margin-top: 10px;
    background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.workExperienceContainer{
    /* border: 5px solid #35db14; */
    margin: 1rem;
    border-radius: 1rem;
    /* width: 100%; */
    height: 100%;
}

.workExperience{
    /* border: 2px solid #1421db; */
    /* margin: 1rem; */
    /* height: 100%; */
    /* border-radius: 1rem; */
} 

.companiesList{
     /* border: 5px solid #1421db; */
    /* border-radius: 1rem; */
    list-style: none;
    /* padding: 40px; */
    padding: 0;
    margin: 0;
    /* margin: 1rem; */
}

.companiesListItems{
    /* list-style: none; */
    /* padding: 40px; */
    /* border: 5px solid #7edb14; */
    padding: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    /* margin-bottom: 1rem; */
}

.companiesContainer{
    /* border: 5px solid #7edb14; */
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */

}

.companyLogoAndNameContainer{
    /* border: 2px solid #d276ad; */
    display: flex;
    /* flex-direction: row; */
    /* margin-left: 2rem; */
    /* margin-right: 2rem; */
    margin-top: 2rem;
    margin-bottom: 0;

}

.companyLogo{
    /* flex: 1; */
    height: 3rem;
    width: 3rem;
    /* border: 2px solid #73c767; */
    background-size: contain;
    /* display: flex; */
    /* flex-direction: row; */
    /* margin: 2rem; */
}

.companyLogo > img{
    height: 3rem;
    width: 3rem;
    border-radius: 1rem;
    background-size: cover;
}

.companyName{
    flex: 1;
    /* border: 2px solid #4d52b1; */
    display: flex;
    /* justify-content: center; */
    /* align-items: flex-start; */
}

.companyName > div{
    /* flex: 1; */
    /* border: 2px solid #4d52b1; */
    display: flex;
    margin-left: .7rem;
    font-size: 1.7rem;
    align-items: center;
    text-align: justify;
}

.workExperienceList{
    /* border: 5px solid #db1714; */
    /* list-style:circle; */
    /* border-radius: 1rem; */
    padding: 0;
    margin-left: 2rem;
    margin-top: .5rem;
    margin-bottom: 0;
}

.workExperienceListItems{
    /* border: 5px solid rgb(101, 52, 194); */
    padding: 1rem;
    margin-bottom: 1rem;
    /* margin-right: 2rem; */
    /* margin-left: 3rem; */
    display: flex;
    flex-direction: column;
    text-align: justify;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

.workExperienceYear{
    box-sizing: border-box;
    width: 100%;
    box-sizing:border-box;
    /* border: 2px solid green; */
    display: flex;
    color: grey;
    flex-direction: row;
    align-items: center;
    margin:  0.5rem 1rem;
}

.workExperienceJobTitle{
    /* border: 2px solid rgb(208, 156, 67); */
    margin:  0.5rem 1rem;
}

.workExperienceJobType{
    /* border: 2px solid rgb(162, 177, 88); */
    margin:  0.5rem 1rem;

}

.workExperienceLocation{
    /* border: 2px solid rgb(162, 177, 88); */
    margin:  0.5rem 1rem;
}

.workExperienceDescription{
    /* border: 2px solid rgb(55, 62, 207); */
    margin:  0.5rem 1rem;
}

@media only screen and (max-width: 850px){
    .workExperienceMainContainer{
        margin: 2rem 1rem;
        /* border: 5px solid rgb(101, 52, 194); */
        height: 100%;
        display: flex;
        flex-direction: column;
        background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .workExperienceHeader > h1{
        text-align: justify;
        font-size: 40px;
        margin-left: 1rem;
        margin-bottom: 0;
        margin-top: 1rem;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    
    .workExperienceContainer{
        /* border: 5px solid #35db14; */
        margin: 1rem;
        border-radius: 1rem;
        /* width: 100%; */
        height: 100%;
    }
    
    .workExperience{
        /* border: 2px solid #1421db; */
        /* margin: 1rem; */
        /* height: 100%; */
        /* border-radius: 1rem; */
    } 
    
    .companiesList{
         /* border: 5px solid #1421db; */
        /* border-radius: 1rem; */
        list-style: none;
        /* padding: 40px; */
        padding: 0;
        margin: 0;
        /* margin: 1rem; */
    }
    
    .companiesListItems{
        /* list-style: none; */
        /* padding: 40px; */
        /* border: 5px solid #7edb14; */
        padding: 0;
        margin-left: 0rem;
        margin-right: 0rem;
        /* margin-bottom: 1rem; */
    }
    
    .companiesContainer{
        /* border: 5px solid #7edb14; */
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
    
    }
    
    .companyLogoAndNameContainer{
        /* border: 2px solid #d276ad; */
        display: flex;
        /* flex-direction: row; */
        /* margin-left: 2rem; */
        /* margin-right: 2rem; */
        margin-top: 1rem;
        margin-bottom: 0;
    
    }
    
    .companyLogo{
        /* flex: 1; */
        height: 3rem;
        width: 3rem;
        /* border: 2px solid #73c767; */
        background-size: contain;
        /* display: flex; */
        /* flex-direction: row; */
        /* margin: 2rem; */
    }
    
    .companyLogo > img{
        height: 3rem;
        width: 3rem;
        border-radius: 1rem;
        background-size: cover;
    }
    
    .companyName{
        flex: 1;
        /* border: 2px solid #4d52b1; */
        display: flex;
        /* justify-content: center; */
        /* align-items: flex-start; */
    }
    
    .companyName > div{
        /* flex: 1; */
        /* border: 2px solid #4d52b1; */
        display: flex;
        margin-left: .7rem;
        font-size: 1.7rem;
        align-items: center;
        text-align: justify;
    }
    
    .workExperienceList{
        /* border: 5px solid #db1714; */
        /* list-style:circle; */
        /* border-radius: 1rem; */
        padding: 0;
        margin-left: 2rem;
        margin-top: .5rem;
        margin-bottom: 0;
    }
    
    .workExperienceListItems{
        /* border: 5px solid rgb(101, 52, 194); */
        padding: 0rem;
        margin-bottom: 1rem;
        /* margin-right: 2rem; */
        /* margin-left: 3rem; */
        display: flex;
        flex-direction: column;
        text-align: justify;
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    .workExperienceYear{
        box-sizing: border-box;
        width: 100%;
        box-sizing:border-box;
        /* border: 2px solid green; */
        display: flex;
        color: grey;
        flex-direction: row;
        align-items: center;
        margin:  0.5rem 1rem;
    }
    
    .workExperienceJobTitle{
        /* border: 2px solid rgb(208, 156, 67); */
        margin:  0.5rem 1rem;
    }
    
    .workExperienceJobType{
        /* border: 2px solid rgb(162, 177, 88); */
        margin:  0.5rem 1rem;
    
    }
    
    .workExperienceLocation{
        /* border: 2px solid rgb(162, 177, 88); */
        margin:  0.5rem 1rem;
    }
    
    .workExperienceDescription{
        /* border: 2px solid rgb(55, 62, 207); */
        margin:  0.5rem 1rem;
    }
}