.educationMainContainer{
    margin: 2rem 8rem;
    /* border: 5px solid rgb(101, 52, 194); */
    height: 100%;
    display: flex;
    flex-direction: column;
    background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    border-radius: 1%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.educationHeader > h1{
    text-align: justify;
    font-size: 50px;
    margin-left: 2rem;
    margin-bottom: 0;
    margin-top: 10px;
    background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.educationContainer{
    /* border: 2px solid #1421db; */
    margin: 1rem;
    border-radius: 1rem;
    /* width: 100%; */
    height: 100%;
}

.education{
    /* border: 2px solid #1421db; */
    margin: 1rem;
    /* height: 100%; */
    border-radius: 1rem;
}

.educationListItems{
    /* border: 2px solid #1421db; */
    /* border-radius: 1rem; */
    list-style: none;
    /* padding: 40px; */
    padding: 0;
    margin: 0;
    /* margin: 1rem; */
}

.educationListItem{
    /* border: 5px solid rgb(101, 52, 194); */
    padding: 1rem;
    margin-bottom: 2rem;
    /* margin-left: 1rem; */
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    text-align: justify;
    /* box-sizing:content-box; */
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

.educationYear{
    box-sizing: border-box;
    width: 100%;
    box-sizing:border-box;
    /* border: 2px solid green; */
    display: flex;
    color: grey;
    flex-direction: row;
    align-items: center;
    margin:  0.5rem 1rem;
}

.educationTitle{
    /* border: 2px solid rgb(208, 156, 67); */
    margin:  0.5rem 1rem;
}

.educationSchool{
    /* border: 2px solid rgb(162, 177, 88); */
    margin:  0.5rem 1rem;

}

.educationEduType{
    /* border: 2px solid rgb(162, 177, 88); */
    margin:  0.5rem 1rem;
}

.educationDescription{
    /* border: 2px solid rgb(55, 62, 207); */
    margin:  0.5rem 1rem;
}

@media only screen and (max-width: 850px) {
    .educationMainContainer{
        margin: 2rem 1rem;
        /* border: 5px solid rgb(101, 52, 194); */
        height: 100%;
        display: flex;
        flex-direction: column;
        background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        border-radius: 1%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .educationHeader > h1{
        text-align: justify;
        font-size: 40px;
        margin-left: 1rem;
        margin-bottom: 0;
        margin-top: 1rem;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    
    .educationContainer{
        /* border: 2px solid #1421db; */
        margin: 0rem;
        border-radius: 1rem;
        /* width: 100%; */
        height: 100%;
    }
    
    .education{
        /* border: 2px solid #1421db; */
        margin: 1rem;
        /* height: 100%; */
        border-radius: 1rem;
    }
    
    .educationListItems{
        /* border: 2px solid #1421db; */
        /* border-radius: 1rem; */
        list-style: none;
        /* padding: 40px; */
        padding: 0;
        margin: 0;
        /* margin: 1rem; */
    }
    
    .educationListItem{
        /* border: 5px solid rgb(101, 52, 194); */
        padding: 0rem;
        margin-bottom: 2rem;
        /* margin-left: 1rem; */
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        text-align: justify;
        /* box-sizing:content-box; */
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    .educationYear{
        box-sizing: border-box;
        width: 100%;
        box-sizing:border-box;
        /* border: 2px solid green; */
        display: flex;
        color: grey;
        flex-direction: row;
        align-items: center;
        margin:  0.5rem 1rem;
    }
    
    .educationTitle{
        /* border: 2px solid rgb(208, 156, 67); */
        margin:  0.5rem 1rem;
    }
    
    .educationSchool{
        /* border: 2px solid rgb(162, 177, 88); */
        margin:  0.5rem 1rem;
    
    }
    
    .educationEduType{
        /* border: 2px solid rgb(162, 177, 88); */
        margin:  0.5rem 1rem;
    }
    
    .educationDescription{
        /* border: 2px solid rgb(55, 62, 207); */
        margin:  0.5rem 1rem;
    }

}