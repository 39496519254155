.App {
  text-align: center;
  height: 100%;
  width: 100vw;
  background-size: cover;
  /* background-color: #9c8b7e; */
  /* border: 2px solid green */
}

root{
  --pink:#ff4757;
}

::selection {
  background: #f1c206;
  color: #000000;
}

*{
  transition: all .3s linear;
}