.homeContainer{
    width: 100vw;
    height: 70%;
}

.homeInputFieldForm{
    width: 100vw;
    height: 70px;
    margin-top: 10px;
    padding: 0;
}

.homeInputFieldForm > input{
    width: 75%;
    height: 50px;
    margin-right:10px;
    padding: 0px;
    text-align: center;
}
.homeInputFieldForm > button{
    width: 20%;
    height: 50px;
    padding: 0;
}