.myBackgroundContainer{
    display: flex;
    margin: 2rem 8rem;
    background-color: #9c8b7e;
    background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    /* border: 5px solid black; */
    /* height: 500px; */
    border-radius: 1rem;
}

.myBackgroundInfoContainer{
    flex: 1;
    display: flex;
    /* margin: 20px; */
    /* margin-left: 40px; */
    text-align: justify;
    /* border: 5px solid black; */
    flex-direction: column;
}

.myBackgroundInfoContainer > h1{
    display: flex;
    box-sizing: border-box;
    justify-content:flex-start;
    /* border: 2px solid black; */
    font-size: 50px;
    margin: 0;
    margin-left: 2rem;
    margin-top: 10px;
    /* height: 40px; */
    /* width: 100%; */
    /* color: white; */
    background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.myBackgroundInfoContainer > div{
    display: flex;
    flex: 1;
    box-sizing: border-box;
    justify-content:flex-start;
    /* border: 2px solid black; */
    font-size: 20px;
    margin: 0;
    margin: 2rem;
    /* color: white; */

}

.myBackgroundImagesContainer{
    flex: 1;
    display: flex;
    border-radius: 10%;

    /* margin: 20px; */
    box-sizing: border-box;
    /* margin: 2rem; */

    /* border: 5px solid black; */
    /* height: auto; */
}

.myBackgroundImagesContainer > img {
    /* border-radius: 10%; */
    /* border: 5px solid black; */
    /* object-fit: contain; */
    /* object-position: center; */
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    background-position:center;
    background-repeat: no-repeat;
    background-size:contain;

}

@media only screen and (max-width: 850px) {
    .myBackgroundContainer{
        display: flex;
        margin: 2rem 1rem;
        background-color: #9c8b7e;
        background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        /* border: 5px solid black; */
        /* height: 500px; */
        border-radius: 1rem;
    }
    
    .myBackgroundInfoContainer{
        flex: 1;
        display: flex;
        /* margin: 20px; */
        /* margin-left: 40px; */
        text-align: justify;
        /* border: 5px solid black; */
        flex-direction: column;
    }
    
    .myBackgroundInfoContainer > h1{
        display: flex;
        box-sizing: border-box;
        justify-content:flex-start;
        /* border: 2px solid black; */
        font-size: 40px;
        margin: 0;
        margin-left: 1rem;
        margin-top: 1rem;
        /* height: 40px; */
        /* width: 100%; */
        /* color: white; */
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .myBackgroundInfoContainer > div{
        display: flex;
        flex: 1;
        box-sizing: border-box;
        justify-content:flex-start;
        /* border: 2px solid black; */
        font-size: 1rem;
        margin: 0;
        margin: 1rem;
        /* color: white; */
    
    }
    
    .myBackgroundImagesContainer{
        flex: 1;
        display: flex;
        border-radius: 10%;
    
        /* margin: 20px; */
        box-sizing: border-box;
        /* margin: 2rem; */
    
        /* border: 5px solid black; */
        /* height: auto; */
    }
    
    .myBackgroundImagesContainer > img {
        /* border-radius: 10%; */
        /* border: 5px solid black; */
        /* object-fit: contain; */
        /* object-position: center; */
        border-radius: 1rem;
        width: 100%;
        height: 100%;
        background-position:center;
        background-repeat: no-repeat;
        background-size:contain;
    
    }

}