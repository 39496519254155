.contactMainContainer{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    margin: 2rem 8rem;
    border-radius: 1rem;
    background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    /* height: 100vh; */
    /* border: 5px solid red; */
}

.contactLeftContainer{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.profilePic{
    padding: 2rem;
    width: 300px;
    height: 300px;
}

.profilePic > img{
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    
}

.description{
    /* border: 5px solid rgb(142, 142, 196); */
    /* height: 400px; */
    display: flex;
    flex-direction: column;
    /* margin: 1rem; */
    padding: 2rem;

}

.descriptionTitle{
    padding: 1rem;
    font-size: 50px;
    font-weight: 500;
}

.contactRightContainer{
    /* border: 5px solid rgb(111, 123, 224); */
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contactForm{
    height: 100%;
    /* width: 100%; */
    /* border: 5px solid blue; */
    margin: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

.firstName{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    /* border: 5px solid rgb(85, 170, 105); */
    height: 5rem;
}

.firstName > label{
    flex: 1;
    text-align: justify;
    /* margin: 2rem; */
    /* height: 2rem; */
}

.firstName > input{
    flex: 2;
    border: 0px ;
    border-radius: .5rem;
    text-align: justify;
    padding: 0;
    padding-left: 1rem;
    margin-top: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



.lastName{
    display: flex;
    flex-direction: column;

    margin: 2rem;
    /* border: 5px solid rgb(48, 123, 173); */
    height: 5rem;

}

.lastName > label{
    flex: 1;
    text-align: justify;
    /* margin: 2rem; */
    /* height: 2rem; */
}

.lastName > input{
    flex: 2;
    border: 0px ;
    border-radius: .5rem;
    text-align: justify;
    padding: 0;
    padding-left: 1rem;
    margin-top: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.email{
    display: flex;
    flex-direction: column;

    margin: 2rem;
    /* border: 5px solid rgb(162, 50, 132); */
    height: 5rem;

}

.email > label{
    flex: 1;
    text-align: justify;
    /* margin: 2rem; */
    /* height: 2rem; */
}

.email > input{
    flex: 2;
    border: 0px ;
    border-radius: .5rem;
    text-align: justify;
    padding: 0;
    padding-left: 1rem;
    margin-top: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.emailBody{
    display: flex;
    flex-direction: column;

    margin: 2rem;
    /* border: 5px solid rgb(255, 140, 0); */
    height: 10rem;

}

.emailBody > label{
    flex: 1;
    text-align: justify;
    /* margin: 2rem; */
    /* height: 2rem; */
}

.emailBody > input{
    flex: 6;
    border: 0px ;
    border-radius: .5rem;
    text-align: justify;
    padding: 0;
    padding-left: 1rem;
    margin-top: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.messageBtn{
    display: flex;
    margin: 2rem;
    /* border: 5px solid rgb(31, 125, 176); */
    height: 3rem;
}

.messageBtn > input{
    border-radius: .5rem;
    text-align: center;
    padding: 0;
    width: 140px;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: antiquewhite;
    /* margin-top: 1rem; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
     /* flex: .5; */
    /* display: flex; */
    /* margin: 2rem; */
    /* border: 5px solid rgb(31, 125, 176); */
    /* height: 3rem; */
}

.screenMessageAlert{
    display: flex;
    flex-direction: column;
    margin: 2rem;
    /* border: 5px solid rgb(162, 50, 132); */
    height: 100%;
}

.screenMessageAlert > div{
    justify-content: center;
    align-content: center;
    height: 100%;
    color: green;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    padding: 0.5rem;

}

.screenMessageAlert > div > p{
    font-size: 20px;
}



@media only screen and (max-width: 850px){
    .contactMainContainer{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 2rem 1rem;
        border-radius: 1rem;
        background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        /* height: 100vh; */
        /* border: 5px solid red; */
    }
    
    .contactLeftContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .profilePic{
        margin-top: 1rem;
        width: 200px;
        height: 200px;
        margin: auto;
    }
    
    .profilePic > img{
        margin-top: 1rem;
        border-radius: 1rem;
        width: 100%;
        height: 100%;
    }
    
    .description{
        /* border: 5px solid rgb(142, 142, 196); */
        /* height: 400px; */
        display: flex;
        flex-direction: column;
        text-align: justify;
        /* margin: 1rem; */
        padding: 1rem;
    
    }
    
    .descriptionTitle{
        padding: 1rem;
        text-align: center;
        font-size: 40px;
        font-weight: 400;
    }
    
    .contactRightContainer{
        /* border: 5px solid rgb(111, 123, 224); */
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .contactForm{
        height: 100%;
        /* width: 100%; */
        /* border: 5px solid blue; */
        margin: 1rem;
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    .firstName{
        display: flex;
        flex-direction: column;
        margin: 2rem;
        /* border: 5px solid rgb(85, 170, 105); */
        height: 5rem;
    }
    
    .firstName > label{
        flex: 1;
        text-align: justify;
        /* margin: 2rem; */
        /* height: 2rem; */
    }
    
    .firstName > input{
        flex: 2;
        border: 0px ;
        border-radius: .5rem;
        text-align: justify;
        padding: 0;
        padding-left: 1rem;
        margin-top: .5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .lastName{
        display: flex;
        flex-direction: column;
        margin: 2rem;
        /* border: 5px solid rgb(48, 123, 173); */
        height: 5rem;
    
    }
    
    .lastName > label{
        flex: 1;
        text-align: justify;
        /* margin: 2rem; */
        /* height: 2rem; */
    }
    
    .lastName > input{
        flex: 2;
        border: 0px ;
        border-radius: .5rem;
        text-align: justify;
        padding: 0;
        padding-left: 1rem;
        margin-top: .5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .email{
        display: flex;
        flex-direction: column;
    
        margin: 2rem;
        /* border: 5px solid rgb(162, 50, 132); */
        height: 5rem;
    
    }
    
    .email > label{
        flex: 1;
        text-align: justify;
        /* margin: 2rem; */
        /* height: 2rem; */
    }
    
    .email > input{
        flex: 2;
        border: 0px ;
        border-radius: .5rem;
        text-align: justify;
        padding: 0;
        padding-left: 1rem;
        margin-top: .5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .emailBody{
        display: flex;
        flex-direction: column;
        margin: 2rem;
        /* border: 5px solid rgb(255, 140, 0); */
        height: 10rem;
    
    }
    
    .emailBody > label{
        flex: 1;
        text-align: justify;
        /* margin: 2rem; */
        /* height: 2rem; */
    }
    
    .emailBody > input{
        flex: 6;
        border: 0px ;
        border-radius: .5rem;
        text-align: justify;
        padding: 0;
        padding-left: 1rem;
        margin-top: .5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .messageBtn{
        display: flex;
        margin: 2rem;
        /* border: 5px solid rgb(31, 125, 176); */
        height: 3rem;
    }
    
    .messageBtn > input{
        border-radius: .5rem;
        text-align: center;
        padding: 0;
        width: 140px;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: antiquewhite;
        /* margin-top: 1rem; */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
         /* flex: .5; */
        /* display: flex; */
        /* margin: 2rem; */
        /* border: 5px solid rgb(31, 125, 176); */
        /* height: 3rem; */
    }
    
    .screenMessageAlert{
        display: flex;
        flex-direction: column;
        margin: 2rem;
        /* border: 5px solid rgb(162, 50, 132); */
        height: 6rem;
    }
    
    .screenMessageAlert > div{
        justify-content: center;
        align-content: center;
        height: 100%;
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        padding: 0.5rem;
    
    }
    
    .screenMessageAlert > div > p{
        font-size: 15px;
    }
    
  }