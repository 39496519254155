.footerMainContainer{
    display: flex;
    flex-direction: column;
    /* margin: 1rem; */
    background-color: #92a490;
    /* border: 5px solid black; */
    /* border-radius: 1rem; */
}

.footerContentContainer{
    /* border: 5px solid rgb(183, 97, 97); */
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.footerContentDetails{
    /* border: 5px solid rgb(220, 169, 169); */
    display: flex;
    justify-content: center; 
    align-items: center;
    font-size: large;
}

.connectionMedia{
    /* flex: 1; */
    /* border: 5px solid rgb(220, 169, 169); */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.linkedInLogosAndName{
    margin: 1rem;
}

.linkedInLogosAndName > a{
    text-decoration: none;
    color: aliceblue;
}

.linkedInName > p{
    margin: 0rem;
}

.githubLogosAndName{
    margin: 1rem;
}

.githubLogosAndName > a{
    text-decoration: none;
    color: aliceblue;
}

.githubName > p{
    margin: 0rem;
}

.technologiesUsed{
    /* flex: 1; */
    /* border: 2px solid black; */
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: justify;
    /* margin: 0.5rem; */
}

.technologiesUsed > div{
    margin: 0.5rem;
}

.technologiesUsed > div > a{
    text-decoration: none;
    color: aliceblue;
}

