@media only screen and (min-width: 851px) {
    .profileContainer{
        display: flex;
        margin: 2rem 8rem;
        /* margin-bottom: 0; */
        /* padding: 0; */
        background-color: #ffff;
        /* border-radius: 50%; */
        /* border: 2px solid black; */
        height: 700px;
    }
    
    .profileName{
        float: 1;
        /* border: 2px solid black; */
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        /* text-align: center; */
        justify-content: center;
        margin: 2rem;
        width: 50%;
    }
    
    .greetingMessage{
        text-align: start;
        font-size: 30px;
    }
    
    .myImName{
        font-size: 50px;
        text-align: start;
        color: #fe4657;
    }
    
    .myName{
        font-size: 50px;
        text-align: start;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;;
    }
    
    .myProfession{
        font-size: 30px;
        font-weight: 200;
    }
    
    .profilePicture{
        width: 50%;
        /* border: 2px solid black; */
        display: flex;
        /* margin: auto; */
    } 
    
    .profilePicture > div {
        display: flex;
        width: 70%;
        margin-top: 8rem;
        margin-bottom: 5rem;
        margin-right: 0;
    }
    
    .profilePicture > div > img {
        border-radius: 1rem;
        background-position:center;
        background-repeat: no-repeat;
        background-size:cover;
        height: 100%;
        width: 100%;
        transition: transform .5s ease-in-out;
    
    }
      
    .profilePicture > div > img:hover {
        transform: scale(1.1);
    }
}



@media only screen and (max-width: 850px) {
    .profileContainer{
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        background-color: #ffff;
        height: 400px;
    }
    
    .profileName{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1rem;
        margin: auto;
        width: auto;
    }
    
    .greetingMessage{
        text-align: center;
        font-size: .8rem;
    }
    
    .myImName{
        font-size: 1.3rem;
        text-align: center;
        color: #fe4657;
    }
    
    .myName{
        font-size: 1.3rem;
        text-align: center;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #f19a49 40%, #d1de5e 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;;
    }
    
    .myProfession{
        font-size: 1.1rem;
        font-weight: 200;
        text-align: center;

    }
    
    .profilePicture{
        
        /* flex: 1; */
        display: flex;
        width: 100%;
        height: 100%; 
        margin: auto;
        /* max-width: 100%;
        max-height: 100%; */
        flex-direction: column;
        /* margin-left: 1rem; */
        /* margin-right: 1rem; */
        /* justify-content: center; */
        /* margin: auto; */
        align-items: center;
    } 
    
    .profilePicture > div {
        display: flex;
        /* box-sizing: border-box; */
        max-width: 100%;
        /* max-height: 100%; */
        height: 80%;
        margin: 1rem;
        /* justify-content: center; */
        /* align-items: center; */
        /* margin-right: 0; */
    }
    
    .profilePicture > div > img {
        border-radius: 1rem;
        background-position:center;
        background-repeat: no-repeat;
        background-size:contain;
        height: 100%;
        /* max-width: 100%; */
        /* max-height: 100%; */
        transition: transform .5s ease-in-out;
    
    }
      
    .profilePicture > div > img:hover {
        transform: scale(1.1);
    }
    
}