.collapsibleContainer{
    /* display: flex; */
    /* flex: 1; */
    /* margin: 5%; */
    /* border: 5px solid green; */
}

.collapsibleTitleContainer{
    display: flex;
    cursor: pointer;
    /* border: 5px solid rgb(42, 51, 157); */

}
.collapsibleTitle{
    /* border: 5px solid rgb(182, 110, 176); */
    flex: 1;
    /* align-self: flex-start; */
}

.collapsibleArrowIcon{
    /* border: 5px solid rgb(211, 63, 70); */

}

.collapsibleDescriptionContainer{
    /* overflow: hidden;
    max-height: 0;
    transition: max-height 0.2s ease-out; */
    border: 1px solid black;
    background-color: #e3d091;
}

.collapsibleDescriptionContainerOpen{
    /* max-height: 500px; 
    transition: max-height 0.8s ease-in; */
    margin-top: 1rem;
    background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.collapsibleDescriptionLinks{
    margin: 1rem;
    text-align: justify;
    display: flex;
    justify-content: center;
}

.collapsibleDescriptionLinkToProject{
    margin: 1rem;
    text-align: justify;
    border: 1px solid black;
    background-color: rgb(163, 183, 212);
    border-radius: 2rem;
    padding: 0.3rem;
    cursor: pointer;

}

.collapsibleDescriptionLinkToProject > a{
   text-decoration: none;
   color: white;
}

.collapsibleDescriptionLinkToGithub{
    margin: 1rem;
    text-align: justify;
    border: 1px solid black;
    background-color: rgb(163, 183, 212);
    border-radius: 2rem;
    padding: 0.3rem;
    cursor: pointer;
}

.collapsibleDescriptionLinkToGithub > a{
    text-decoration: none;
    color: white;
}

.collapsibleDescriptionTechUsed{
    margin: 1rem;
    text-align: justify;
}

.collapsibleDescriptionOverview{
    margin: 1rem;
    text-align: justify;

}

@media only screen and (max-width: 850px) {
    .collapsibleContainer{
        /* display: flex; */
        /* flex: 1; */
        /* margin: 5%; */
        /* border: 5px solid green; */
    }
    
    .collapsibleTitleContainer{
        display: flex;
        cursor: pointer;
        /* border: 5px solid rgb(42, 51, 157); */
    
    }
    .collapsibleTitle{
        /* border: 5px solid rgb(182, 110, 176); */
        flex: 1;
        /* align-self: flex-start; */
    }
    
    .collapsibleArrowIcon{
        /* border: 5px solid rgb(211, 63, 70); */
    
    }
    
    .collapsibleDescriptionContainer{
        /* overflow: hidden;
        max-height: 0;
        transition: max-height 0.2s ease-out; */
        border: 1px solid black;
        background-color: #e3d091;
    }
    
    .collapsibleDescriptionContainerOpen{
        /* max-height: 500px; 
        transition: max-height 0.8s ease-in; */
        margin-top: .5rem;
        background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        border: 1px solid black;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .collapsibleDescriptionLinks{
        margin: 1rem;
        text-align: justify;
        display: flex;
        justify-content: center;
    }
    
    .collapsibleDescriptionTechUsed{
        margin: 1rem;
        text-align: justify;
    }
    
    .collapsibleDescriptionOverview{
        margin: 1rem;
        text-align: justify;
    
    }
    
    .collapsibleDescriptionLinkToProject{
        margin: 1rem;
        text-align: justify;
        border: 1px solid black;
        background-color: rgb(163, 183, 212);
        border-radius: 10px;
        padding: 0.3rem;
        cursor: pointer;
    
    }
    
    .collapsibleDescriptionLinkToProject > a{
       text-decoration: none;
       color: white;
    }
    
    .collapsibleDescriptionLinkToGithub{
        margin: 1rem;
        text-align: justify;
        border: 1px solid black;
        background-color: rgb(163, 183, 212);
        border-radius: 10px;
        padding: 0.3rem;
        cursor: pointer;
    }
    
    .collapsibleDescriptionLinkToGithub > a{
        text-decoration: none;
        color: white;
    }
}