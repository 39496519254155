.skillsMainContainer{
    margin: 2rem 8rem;
    /* border: 5px solid rgb(101, 52, 194); */
    height: 100%;
    display: flex;
    flex-direction: column;
    background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

.skillsHeader{
    flex: 1;
    /* border: 5px solid rgb(101, 52, 194); */
}

.skillsHeader > h1{
    font-size: 50px;
    margin: 0;
    margin-left: 2rem;
    margin-top: 10px;
    text-align: justify;
    background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skillsContainer{
    margin: 1rem;
    /* border: 5px solid rgb(101, 52, 194); */
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.technicalSkillsContainer{
    /* border: 5px solid rgb(133, 203, 129); */
    flex: 1;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}

h3{
    font-size: 25px;
    margin: .5rem;
    background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #43b439 20%, #666dd0 30%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.technicalSkills{
    text-align: justify;
}

.technicalSkillsList{
    list-style: none;
    padding: 2rem;
    padding-top: 1rem;
    margin: 0;
}

.technicalSkillsListItems{
    margin-top: 0.2rem;
    justify-content: flex-start;
    /* padding-bottom: .2rem; */
    display: flex;
}

.technicalSkillsListItemsIcon{
    margin-right: .5rem;
}

.personalSkillsContainer{
    /* border: 5px solid rgb(177, 16, 177); */
    flex: 1;
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.personalSkills{
    text-align: justify;
}

.personalSkillsList{
    list-style: none;
    padding: 2rem;
    padding-top: 1rem;
    margin: 0;
}

.personalSkillsListItems{
    margin-top: 0.2rem;
    justify-content: flex-start;
    /* padding-bottom: .2rem; */
    display: flex;
}

.personalSkillsListItemsIcon{
    margin-right: .5rem;
}

@media only screen and (max-width: 850px) {
    .skillsMainContainer{
        margin: 2rem 1rem;
        /* border: 5px solid rgb(101, 52, 194); */
        height: 100%;
        display: flex;
        flex-direction: column;
        background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    .skillsHeader{
        flex: 1;
        /* border: 5px solid rgb(101, 52, 194); */
    }
    
    .skillsHeader > h1{
        font-size: 40px;
        margin: 0;
        margin-left: 1rem;
        margin-top: 1rem;
        text-align: justify;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .skillsContainer{
        margin: 0rem;
        /* border: 5px solid rgb(101, 52, 194); */
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    
    }
    
    .technicalSkillsContainer{
        /* border: 5px solid rgb(133, 203, 129); */
        flex: 1;
        margin: 1rem;
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    h3{
        font-size: 25px;
        margin: .5rem;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #43b439 20%, #666dd0 30%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .technicalSkills{
        text-align: justify;
    }
    
    .technicalSkillsList{
        list-style: none;
        padding: 1rem;
        padding-top: 1rem;
        margin: 0;
    }
    
    .technicalSkillsListItems{
        margin-top: 0.2rem;
        justify-content: flex-start;
        /* padding-bottom: .2rem; */
        display: flex;
    }
    
    .technicalSkillsListItemsIcon{
        margin-right: .5rem;
    }
    
    .personalSkillsContainer{
        /* border: 5px solid rgb(177, 16, 177); */
        flex: 1;
        margin: 1rem;
        border-radius: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    
    .personalSkills{
        text-align: justify;
    }
    
    .personalSkillsList{
        list-style: none;
        padding: 1rem;
        padding-top: 1rem;
        margin: 0;
    }
    
    .personalSkillsListItems{
        margin-top: 0.2rem;
        justify-content: flex-start;
        /* padding-bottom: .2rem; */
        display: flex;
    }
    
    .personalSkillsListItemsIcon{
        margin-right: .5rem;
    }
}