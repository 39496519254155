.projectsContainerWithClosedProjectDetailsCollapsible{
    display: flex;
    margin: 2rem 8rem;
    border-radius: 1rem;
    /* padding: 20px; */
    /* box-sizing: border-box; */
    /* border: 5px solid black; */
    /* width: 100vw; */
    /* background-color: #d4d2cc; */
    background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    overflow: hidden;
    max-height: 100%;
    transition: max-height 0.2s ease-out;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.projectsContainerWithOpenProjectDetailsCollapsible{
    /* border: 2px solid blueviolet; */
    display: flex;
    margin: 2rem 8rem;
    border-radius: 1rem;
    /* margin-top: 1rem; */
    /* margin-top: 0.5rem; */
    /* box-sizing: border-box; */
    /* border: 5px solid black; */
    /* width: 100%; */
    /* background-color: #d4d2cc; */
    background:repeating-linear-gradient(135deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
    max-height: 100%; 
    transition: max-height 0.9s ease-in;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

}


.projectsLeftContainer{
    /* border: 2px solid blueviolet; */
    flex: 1;
    align-self: flex-start;
    height: 300px;
    /* display: flex; */
    margin: 2rem;
    margin-bottom: 0;
    border-radius: 10%;
}

.projectsLeftContainer > img{
    border-radius: 2rem;
    background-size:contain;
    object-fit:fill;
    object-position: center;
    width: 100%;
    height: 100%;
}

.projectsContentContainer{
    /* border: 2px solid #8a2be2; */
    flex: 2.5;
    text-align: justify;
    /* display: flex; */
    /* justify-items: center; */
}

.projectsContentContainer > h1{
    text-align: center;
    margin: 2rem;
    font-size: 50px;
    background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.projectsContentContainer > p{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 20%;
    margin-left: 20%;
}

.projectsContentContainerProjectsList{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 20%;
    margin-left: 20%;
}

.projectsRightContainer{
    /* border: 2px solid blueviolet; */
    flex: 1;
    align-self: flex-end;
    margin: 2rem;
    margin-top: 0;
    border-radius: 2rem;
    height: 300px;
}

.projectsRightContainer > img{
    border-radius: 10%;
    object-fit: fill;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 850px) {
    .projectsContainerWithClosedProjectDetailsCollapsible{
        display: flex;
        margin: 2rem 1rem;
        border-radius: 1rem;
        /* padding: 20px; */
        /* box-sizing: border-box; */
        /* border: 5px solid black; */
        /* width: 100vw; */
        /* background-color: #d4d2cc; */
        background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        overflow: hidden;
        max-height: 100%;
        transition: max-height 0.2s ease-out;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    .projectsContainerWithOpenProjectDetailsCollapsible{
        /* border: 2px solid blueviolet; */
        display: flex;
        margin: 2rem 1rem;
        border-radius: 1rem;
        /* margin-top: 1rem; */
        /* margin-top: 0.5rem; */
        /* box-sizing: border-box; */
        /* border: 5px solid black; */
        /* width: 100%; */
        /* background-color: #d4d2cc; */
        background:repeating-linear-gradient(45deg,hsla(157, 14%, 78%, 0.49) 10%, #fff 40%);
        max-height: 100%; 
        transition: max-height 0.9s ease-in;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    
    }
    
    
    .projectsLeftContainer{
        /* border: 2px solid blueviolet; */
        display: none;
        flex: 1;
        align-self: flex-start;
        height: 300px;
        /* display: flex; */
        margin: 2rem;
        margin-bottom: 0;
        border-radius: 10%;
    }
    
    .projectsLeftContainer > img{
        border-radius: 2rem;
        background-size:contain;
        object-fit:fill;
        object-position: center;
        width: 100%;
        height: 100%;
    }
    
    .projectsContentContainer{
        /* border: 2px solid #8a2be2; */
        flex: 2.5;
        text-align: justify;
        /* display: flex; */
        /* justify-items: center; */
    }
    
    .projectsContentContainer > h1{
        text-align: justify;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 0rem;
        margin-bottom: 0rem;
        font-size: 40px;
        background:repeating-linear-gradient(45deg,hsla(28, 60%, 60%, 0.49) 10%, #75e46b 40%, #1421db 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .projectsContentContainer > p{
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 1rem;
    }
    
    .projectsContentContainerProjectsList{
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
        margin-left: 1rem;
    }
    
    .projectsRightContainer{
        /* border: 2px solid blueviolet; */
        display: none;
        flex: 1;
        align-self: flex-end;
        margin: 2rem;
        margin-top: 0;
        border-radius: 2rem;
        height: 300px;
    }
    
    .projectsRightContainer > img{
        border-radius: 10%;
        object-fit: fill;
        width: 100%;
        height: 100%;
    }

}