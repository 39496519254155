.navbarContainer{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #92a490;
    justify-content:flex-end;
    height: 6rem;
}
.navbarContainer a{
    display: flex;
    align-items:center;
    justify-content:center;
    padding: 0.3rem .5rem;
    margin: 1rem 1rem;
    color: #ffff;
    text-decoration: none;
    border-radius: 10%;
}
.navbarContainer a:hover{
    /* background-color: #ccc; */
    cursor: pointer;
    color: rgb(119, 50, 203);
    transform: scale(1.2) ;
    /* text-decoration:underline; */

}
